import React from "react"

import Layout from "../../layouts/Fi"

import SEO from "../../components/SEO"
import CheckPoint from "../../components/Checkpoint"

const OhjelmistokehitysPage = () => (
  <Layout title="Ohjelmisto&shy;kehitys">
    <SEO keywords={[`ohjelmistokehitys`]} title="Ohjelmistokehitys" />

    <section className="flex justify-center mb-12">
      <div className="w-full sm:w-3/4 md:w-2/3">
        <p className="mb-8">
          Olemme tehneet mm. tarjouslaskenta-, kustannusseuranta- ja teknisten
          laitteiden valintasovelluksia. Erikoisosaamistamme ovat Web- ja
          Windows -sovellukset, mm. Joomla -julkaisujärjestelmän komponentit.
        </p>
        <h3 className="mb-4">Asiakaskohtaisia toteutuksia:</h3>
        <CheckPoint className="mb-8">
          Autokaupan tarjouslaskentaohjelma
        </CheckPoint>
        <CheckPoint className="mb-8">
          XXL -tapahtuman fanikuvauksen tekninen toteutus
        </CheckPoint>
        <CheckPoint className="mb-8">
          Kair IV -koneiden valintaohjelmisto (
          <a className="text-orange" href="https://www.kair.fi/suunnittelija">
            www.kair.fi/suunnittelija
          </a>
          )
        </CheckPoint>
        <CheckPoint className="mb-8">
          Tiedonsiirto Lemonsoft -toiminnanohjausjärjestelmästä Monitor ERP
          -järjestelmään
        </CheckPoint>
        <CheckPoint className="mb-8">
          Siemens -automaatiojärjestelmästä tulevien hälytysten ohjaus eri
          viestikanaviin
        </CheckPoint>
        <CheckPoint>DWG -tiedostojen koneellinen luonti ja muokkaus</CheckPoint>
      </div>
    </section>

    <section className="flex justify-center mb-12">
      <div className="w-full sm:w-3/4 md:w-2/3">
        <p className="mb-8">
          Ohjelmistointegraatioita teemme monipuolisesti vahvalla
          ammattitaidolla. Vuosikymmenten kokemus ja jatkuva kouluttautuminen
          mahdollistavat nopeat ratkaisut. Meille ovat tulleet tutuiksi eri
          järjestelmien tavat käsitellä tietokantoja sekä vanhojenkin
          järjestelmien toimintatavat ja rajoitteet. Projekteissa voimme
          hyödyntää myös taloushallinnon osaamistamme.
        </p>
        <CheckPoint className="mb-8">Monitor ERP -järjestelmä</CheckPoint>
        <CheckPoint className="mb-8">
          EmCe taloushallinto- ja kirjanpitojärjestelmä
        </CheckPoint>
        <CheckPoint className="mb-8">
          Passeli kassa- ja varastokirjanpito
        </CheckPoint>
        <CheckPoint className="mb-8">
          Lemonsoft -toiminnanohjausjärjestelmä
        </CheckPoint>
        <CheckPoint className="mb-8">Skalex -kaatopaikkaohjelmisto</CheckPoint>
        <CheckPoint className="mb-8">Siemens SIMATIC S7</CheckPoint>
        <CheckPoint className="mb-8">Siemens WinCC</CheckPoint>
        <CheckPoint className="mb-8">InstaWahti</CheckPoint>
        <CheckPoint>Kantech EntraPass</CheckPoint>
      </div>
    </section>
  </Layout>
)

export default OhjelmistokehitysPage
