import PropTypes from "prop-types"
import React from "react"

import Check from "../icons/check.svg"

const CheckPoint = ({ children, className }) => (
  <div className={`flex ${className}`}>
    <div className="w-8 mr-4">
      <Check className="text-orange" />
    </div>
    <div className="w-auto">
      <p className="mb-0">{children}</p>
    </div>
  </div>
)

CheckPoint.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
}

export default CheckPoint
